import type { ReactNode } from 'react';
import React, { Fragment, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import { Inline, Grid, Box, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import { token } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { ContentTreeIcon } from '@confluence/icons/entry-points/ContentTreeIcon';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER as persistedLocalStorageKeys,
} from '@confluence/storage-manager';
import { ErrorDisplay, isExpectedApolloError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import type {
	ConvertPageToFolderBannerQuery as ConvertPageToFolderBannerQueryType,
	ConvertPageToFolderBannerQueryVariables,
} from './__types__/ConvertPageToFolderBannerQuery';
import { ConvertPageToFolderBannerQuery } from './ConvertPageToFolderBannerQuery.graphql';

const i18n = defineMessages({
	convertToFolderBanner: {
		id: 'folder.convert-page-to-folder.banner.message',
		defaultMessage:
			'Simplify your space by converting parent items to folders. <link>Show me how</link>',
		description:
			'Message on the folder conversion banner that appears on a page. It informs the user that they can convert pages to folders. At the end is a link that when clicked, opens the more actions menu which contains the "Convert to folder" button.',
	},
	bannerClose: {
		id: 'folder.convert-page-to-folder.banner.close',
		defaultMessage: 'Close',
		description:
			'Label for the x icon in the folder conversion banner. Clicking this icon closes the banner',
	},
});

const STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER =
	persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED;

const bannerStyles = xcss({
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	borderBottom: `1px solid ${token('color.background.discovery.hovered')}`,
	backgroundColor: 'color.background.discovery',
});

const bannerMessageStyles = xcss({
	gridArea: 'message',
});

const closeButtonStyles = xcss({
	display: 'flex',
	justifySelf: 'end',
	gridArea: 'close',
});

type ConvertPageToFolderBannerProps = {
	contentId: string;
	spaceKey: string;
	contentType: string;
};

export const ConvertPageToFolderBanner = ({
	contentId,
	spaceKey,
	contentType,
}: ConvertPageToFolderBannerProps) => {
	const { formatMessage } = useIntl();
	const [showBanner, setShowBanner] = useState<boolean>(false);

	const isFolderConversionEnabled = FeatureGates.checkGate(
		'confluence_enable_page_to_folder_conversion',
	);
	const hasDismissedBanner = Boolean(
		localStorage?.getItem(
			persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED,
		),
	);
	const canShowBanner = isFolderConversionEnabled && contentType === 'page' && !hasDismissedBanner;

	const { loading, error } = useQuery<
		ConvertPageToFolderBannerQueryType,
		ConvertPageToFolderBannerQueryVariables
	>(ConvertPageToFolderBannerQuery, {
		skip: !canShowBanner,
		variables: { contentId, spaceKey },
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			const isFoldersEnabled =
				data?.space?.spaceTypeSettings?.enabledContentTypes?.isFoldersEnabled;
			const contentNode = data?.content?.nodes?.[0];
			const canEdit =
				(contentNode?.operations?.filter(
					(operationCheckResult: any) =>
						operationCheckResult?.operation === 'update' &&
						operationCheckResult?.targetType === 'page',
				)?.length || 0) > 0;
			const isParent = (contentNode?.children?.count || 0) > 0;
			const isContentBodyEmpty = contentNode?.body?.storage?.value === '';
			const shouldShowBanner =
				(isFoldersEnabled && canEdit && isParent && isContentBodyEmpty) || false;
			setShowBanner(shouldShowBanner);
		},
		onError: (error) => {
			if (isExpectedApolloError(error)) {
				markErrorAsHandled(error);
			}
		},
	});

	const handleOnClickClose = () => {
		localStorage?.setItem(STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER, true);
		setShowBanner(false);
	};

	return (
		<Fragment>
			{!loading && showBanner && (
				<Grid
					templateColumns="1fr auto 1fr"
					templateAreas={['empty message close']}
					xcss={bannerStyles}
				>
					<Inline
						space="space.100"
						alignInline="center"
						alignBlock="center"
						xcss={bannerMessageStyles}
					>
						<ContentTreeIcon type="folder" label="" spacing="spacious" />
						<FormattedMessage
							{...i18n.convertToFolderBanner}
							values={{
								link: (chunks: ReactNode[]) => (
									<Button appearance="link" spacing="none" onClick={() => {}}>
										{chunks}
									</Button>
								),
							}}
						/>
					</Inline>
					<Box xcss={closeButtonStyles}>
						<IconButton
							appearance="subtle"
							spacing="compact"
							icon={EditorCloseIcon}
							label={formatMessage(i18n.bannerClose)}
							onClick={handleOnClickClose}
						/>
					</Box>
				</Grid>
			)}
			{error && <ErrorDisplay error={error} />}
		</Fragment>
	);
};
