import gql from 'graphql-tag';

export const ConvertPageToFolderBannerQuery = gql`
	query ConvertPageToFolderBannerQuery($contentId: ID!, $spaceKey: String!) {
		content(id: $contentId) {
			nodes {
				id
				body {
					storage {
						value
					}
				}
				operations {
					operation
					targetType
				}
				children {
					count
				}
			}
		}
		space(key: $spaceKey) {
			id
			spaceTypeSettings {
				enabledContentTypes {
					isFoldersEnabled
				}
			}
		}
	}
`;
